import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "../styles/styles.scss";

const Layout = ({ children }) => {
    return (
        <div className="body">
            <Header />
            <div className="main">{children}</div>
            <Footer />
        </div>
    );
};

export default Layout;
